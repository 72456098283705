import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import NavSideLayout from "../components/NavSideLayout";

export default class NoMatch extends Component {
  render() {
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <NavSideLayout />

          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>
            {/* /.content-header */}
            {/* Main content */}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="info-box bg-gradient-warning">
                      <div className="info-box-content">
                        <center>
                          <h2>404 Page Not Found</h2>
                        </center>
                      </div>
                      {/* /.info-box-content */}
                    </div>
                    {/* /.info-box */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}
